import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";

import "react-modal-video/scss/modal-video.scss";
import VideoModal from "../components/VideoModal";

import "typeface-montserrat";
import "typeface-lato";
import Image from "gatsby-image";

import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";

import Carousel from "nuka-carousel";

import Layout from "../components/Layout";
import Navbar from "../components/Navbar";

import Helmet from 'react-helmet'
import Favicon from '../img/favicon/favicon.ico'
import '../fonts/signature-font.css'

export const IndexPageTemplate = ({
  logo,
  image,
  title,
  subtitle,
  slides,
  section1,
  rooms,
  adventures,
  contact,
  preview
}) => (
    <div>
      <Helmet>
        <link rel="shortcut icon" href={Favicon} type="image/x-icon" />
        <link rel="icon" href={Favicon} type="image/x-icon"></link>
      </Helmet>
      <div id="top">
        <Navbar />
        <section
          className="hero is-fullheight-with-navbar full-width-image"
          style={{
            backgroundImage: `url(${
              !!image.childImageSharp ? image.childImageSharp.fluid.src : image
              })`,
            backgroundPosition: `center 80%`,
            backgroundSize: 'cover',
          }}
        >
          <div className="hero-body">
            <div className="container has-text-centered">
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <div className="is-flex" style={{ justifyContent: "center" }}>
                  <figure className="image onda-o-logo" style={{ padding: '0 0rem' }}>
                    {!!logo.childImageSharp ?
                      <Image
                        fluid={
                          !!logo.childImageSharp ? logo.childImageSharp.fluid : logo.url
                        }
                      />
                      :
                      <img src={logo} alt="onda-logo" />
                    }
                  </figure>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp" animateOnce={true}>
                <h2
                  className="has-text-weight-medium is-size-3-mobile is-size-1-tablet is-size-1-widescreen is-uppercase"
                  style={{
                    fontSize: "5.826rem",
                    lineHeight: 1.2,
                    letterSpacing: ".2rem",
                    color: "white",
                    padding: "0.25em"
                  }}
                >
                  {title}
                </h2>
                <h3
                  className="has-text-weight-medium is-size-5-mobile is-size-5-tablet is-size-4-widescreen is-uppercase"
                  style={{
                    color: "white",
                    lineHeight: "1",
                    padding: "0.25em"
                  }}
                >
                  {subtitle}
                </h3>
              </ScrollAnimation>
            </div>
          </div>
        </section>
      </div>
      <section className="hero is-fullheight is-relative" id="queonda">
        <div className="columns">
          <div
            className="column is-half is-offset-half video-image"
          >
            {!!section1.videoImage.childImageSharp ?
              <Image
                className="image-full"
                style={{ position: "bottom center" }}
                fluid={
                  !!section1.videoImage.childImageSharp
                    ? section1.videoImage.childImageSharp.fluid
                    : section1.videoImage
                }
              />
              :
              <img src={section1.videoImage} />
            }
            <div
              style={{
                position: "absolute",
                top: "calc(50% - 32px)",
                left: "calc(50% - 32px)",
                zIndex: 10
              }}
            >
              <VideoModal></VideoModal>
            </div>
          </div>
        </div>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-half content" style={{ padding: "6rem 2rem" }}>
                {preview === false ?
                  <ScrollAnimation animateIn="fadeInLeft" animateOnce={true} duration={1.5}>
                    <h2 className="title" style={{ paddingBottom: '2rem' }}><span className="swoosh">{section1.title}</span></h2>
                    <p className="subtitle signature-font">{section1.subTitle}</p>
                    <ReactMarkdown
                      source={section1.description}
                      className="content"
                    />
                    <span className="subtitle signature-font swoosh">Hecho en México</span>
                  </ScrollAnimation>
                  :
                  <>
                    <h2 className="title" style={{ paddingBottom: '2rem' }}><span className="swoosh">{section1.title}</span></h2>
                    <p className="subtitle signature-font">{section1.subTitle}</p>
                    <ReactMarkdown
                      source={section1.description}
                      className="content"
                    />
                    <span className="subtitle signature-font swoosh">Hecho en México</span>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {rooms.roomList.map((room, index) => (
        <section key={`${room.id}-${index}`} className="is-relative" id={`${index === 0 ? "rooms" : ""}`} style={{ marginTop: '10.5px' }}>
          {index === 0 ? (
            <div className={`column is-half fullwidth-image ${index === 1 ? 'is-offset-half' : ''}`} style={{ padding: '0 0', top: 0, left: 0, right: 0, bottom: 0 }}>
              {!!room.image.childImageSharp ?
                <Image
                  className="image-full"
                  style={{ height: "100%" }}
                  fluid={
                    !!room.image.childImageSharp
                      ? room.image.childImageSharp.fluid
                      : room.image
                  }
                />
                :
                <img src={room.image} alt="room-image" />
              }
            </div>
          ) : (
              ""
            )}
          {index === 1 ? (
            <div className={`column is-half fullwidth-image ${index === 1 ? 'is-offset-half' : ''}`} style={{ padding: '0 0', top: 0, left: 0, right: 0, bottom: 0 }}>
              {!!room.image.childImageSharp ?

                <Image
                  className="image-full"
                  style={{ height: "100%" }}
                  fluid={
                    !!room.image.childImageSharp
                      ? room.image.childImageSharp.fluid
                      : room.image
                  }
                />
                :
                <img src={room.image} alt="room-image" />
              }
            </div>
          ) : (
              ""
            )}
          <div className="container is-relative">
            <div className={`columns ${index === 1 ? 'reverse-columns' : ''}`} >

              <div
                className={`column is-half ${index === 0 ? 'is-offset-half' : ''} `} style={{ padding: '0 0', }}
              >
                <div className="content" style={{ padding: "12rem 3rem" }}>
                  {preview === false ?
                    <ScrollAnimation animateIn={index === 0 ? "fadeInRight" : "fadeInLeft"} animateOnce={true} duration={1.5}>
                      <h2 className="title is-uppercase has-text-weight-medium">
                        <span className="swoosh">
                          {room.title}
                        </span>
                      </h2>
                      <p>{room.description}</p>
                      <div>
                        <a
                          href={`https://hotels.cloudbeds.com/reservation/Pj8cCc#room_type=${room.roomId}`}
                          className="button is-outlined is-primary is-uppercase"
                          style={{ borderRadius: 0 }}
                        >
                          Book Now
                  </a>
                      </div>
                    </ScrollAnimation>
                    :
                    <>
                      <h2 className="title is-uppercase has-text-weight-medium">
                        <span className="swoosh">
                          {room.title}
                        </span>
                      </h2>
                      <p>{room.description}</p>
                      <div>
                        <a
                          href={`https://hotels.cloudbeds.com/reservation/Pj8cCc#room_type=${room.roomId}`}
                          className="button is-outlined is-primary is-uppercase"
                          style={{ borderRadius: 0 }}
                        >
                          Book Now
                  </a>
                      </div>
                    </>
                  }
                </div>
              </div>

            </div>
          </div>
        </section>
      ))}
      <section>
        <div className="container is-fluid">
          <div className="container">
            <div className="columns">
              <div className="column">
                <div className="has-text-centered" style={{ padding: '2rem 0' }}>
                  <a
                    href="https://hotels.cloudbeds.com/reservation/Pj8cCc#room_type=STD"
                    className="button is-primary is-outlined is-uppercase is-medium"
                    style={{ borderRadius: 0, padding: '1rem 5rem' }}
                  >
                    See All Rooms
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="amenities" className="">
        <div className="container is-widescreen">

          {preview === false ?
            <ScrollAnimation animateIn={"fadeIn"} animateOnce={true} duration={1.5}>
              <Carousel style={{ height: "100%" }} dragging={true} heightMode="max">
                {slides.slideList.map(slide => (
                  <div
                    key={slide.title}
                    className="columns is-mobile is-multiline is-gapless"
                    style={{ height: "100vh" }}
                  >
                    <div
                      className="column is-one-third-desktop is-half-touch"
                      style={{ maxHeight: "100%" }}
                    >
                      <div style={{ maxHeight: "inherit", height: "100%" }}>
                        <div
                          className="has-text-centered is-vertical-center"
                          style={{ height: "50%" }}
                        >
                          <h4 className="title is-size-6 is-uppercase has-text-weight-medium">
                            {slide.title}
                          </h4>
                          <p className="is-size-7">{slide.description}</p>
                        </div>
                        {!!slide.image1.childImageSharp ?
                          <Image
                            fluid={
                              !!slide.image1.childImageSharp
                                ? slide.image1.childImageSharp.fluid
                                : slide.image1
                            }
                            style={{ height: "50%" }}
                            imgStyle={{}}
                            placeholderStyle={{}}
                          />
                          :
                          <img src={slide.image1} style={{ height: "50%" }} />
                        }
                      </div>
                    </div>
                    <div
                      className="column is-one-third-desktop is-half-touch"
                      style={{ maxHeight: "100%" }}
                    >
                      <div style={{ maxHeight: "100%", height: "100%" }}>
                        {!!slide.image2.childImageSharp ?
                          <Image
                            fluid={
                              !!slide.image2.childImageSharp
                                ? slide.image2.childImageSharp.fluid
                                : slide.image2
                            }
                            style={{ height: "100%" }}
                            imgStyle={{ height: "100%" }}
                          />
                          :
                          <img src={slide.image2} style={{ height: "100%" }} />
                        }
                      </div>
                    </div>
                    <div
                      className="column is-one-third-desktop"
                      style={{ maxHeight: "100%" }}
                    >
                      {!!slide.image3.childImageSharp ?
                        <Image
                          fluid={
                            !!slide.image3.childImageSharp
                              ? slide.image3.childImageSharp.fluid
                              : slide.image3
                          }
                          style={{ height: "50%" }}
                          imgStyle={{}}
                        />
                        :
                        <img src={slide.image3} style={{ height: "50%" }} />
                      }
                      {!!slide.image4.childImageSharp ?
                        <Image
                          fluid={
                            !!slide.image4.childImageSharp
                              ? slide.image4.childImageSharp.fluid
                              : slide.image4
                          }
                          style={{ height: "50%" }}
                          imgStyle={{}}
                        />
                        :
                        <img src={slide.image4} style={{ height: "50%" }} />
                      }
                    </div>
                  </div>
                ))}
              </Carousel>
            </ScrollAnimation>
            :
            <>
              <Carousel style={{ height: "100%" }} dragging={true} heightMode="max">
                {slides.slideList.map(slide => (
                  <div
                    key={slide.title}
                    className="columns is-mobile is-multiline is-gapless"
                    style={{ height: "100vh" }}
                  >
                    <div
                      className="column is-one-third-desktop is-half-touch"
                      style={{ maxHeight: "100%" }}
                    >
                      <div style={{ maxHeight: "inherit", height: "100%" }}>
                        <div
                          className="has-text-centered is-vertical-center"
                          style={{ height: "50%" }}
                        >
                          <h4 className="title is-size-6 is-uppercase has-text-weight-medium">
                            {slide.title}
                          </h4>
                          <p className="is-size-7">{slide.description}</p>
                        </div>
                        {!!slide.image1.childImageSharp ?
                          <Image
                            fluid={
                              !!slide.image1.childImageSharp
                                ? slide.image1.childImageSharp.fluid
                                : slide.image1
                            }
                            style={{ height: "50%" }}
                            imgStyle={{}}
                            placeholderStyle={{}}
                          />
                          :
                          <img src={slide.image1} style={{ height: "50%" }} />
                        }
                      </div>
                    </div>
                    <div
                      className="column is-one-third-desktop is-half-touch"
                      style={{ maxHeight: "100%" }}
                    >
                      <div style={{ maxHeight: "100%", height: "100%" }}>
                        {!!slide.image2.childImageSharp ?
                          <Image
                            fluid={
                              !!slide.image2.childImageSharp
                                ? slide.image2.childImageSharp.fluid
                                : slide.image2
                            }
                            style={{ height: "100%" }}
                            imgStyle={{ height: "100%" }}
                          />
                          :
                          <img src={slide.image2} style={{ height: "100%" }} />
                        }
                      </div>
                    </div>
                    <div
                      className="column is-one-third-desktop"
                      style={{ maxHeight: "100%" }}
                    >
                      {!!slide.image3.childImageSharp ?
                        <Image
                          fluid={
                            !!slide.image3.childImageSharp
                              ? slide.image3.childImageSharp.fluid
                              : slide.image3
                          }
                          style={{ height: "50%" }}
                          imgStyle={{}}
                        />
                        :
                        <img src={slide.image3} style={{ height: "50%" }} />
                      }
                      {!!slide.image4.childImageSharp ?
                        <Image
                          fluid={
                            !!slide.image4.childImageSharp
                              ? slide.image4.childImageSharp.fluid
                              : slide.image4
                          }
                          style={{ height: "50%" }}
                          imgStyle={{}}
                        />
                        :
                        <img src={slide.image4} style={{ height: "50%" }} />
                      }
                    </div>
                  </div>
                ))}
              </Carousel>
            </>
          }
        </div>
      </section>
      <section id="adventures" className="hero is-medium">
        <div className="hero-body">
          <div className="container has-text-centered">
            <div className="columns is-centered">
              <div className="column is-two-thirds">
                {preview === false ?
                  <ScrollAnimation animateIn={"fadeInUp"} animateOnce={true} duration={1.5}>
                    <h2 className="title"><span className="swoosh">{adventures.title}</span></h2>
                    <ReactMarkdown
                      source={adventures.description}
                      className="content"
                    />
                    <ReactMarkdown
                      source={adventures.adventures}
                      className="content signature-font"
                    />
                  </ScrollAnimation>
                  :
                  <>
                    <h2 className="title"><span className="swoosh">{adventures.title}</span></h2>
                    <ReactMarkdown
                      source={adventures.description}
                      className="content"
                    />
                    <ReactMarkdown
                      source={adventures.adventures}
                      className="content signature-font"
                    />
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact" className="is-relative" style={{ overflow: 'hidden' }}>
        <div className={`column is-half fullwidth-image`} style={{ padding: 0, top: '0%', bottom: 0, left: 0, right: 0 }}>
          {preview === false ?
            <ScrollAnimation animateIn={"fadeIn"} animateOnce={true} duration={1.5} style={{ height: '100%' }}>
              {!!contact.image.childImageSharp ?
                <Image
                  className="image-full"
                  style={{ height: "100%" }}
                  fluid={
                    !!contact.image.childImageSharp
                      ? contact.image.childImageSharp.fluid
                      : contact.image
                  }
                />
                :
                <img src={contact.image} style={{ height: "100%" }} />
              }
            </ScrollAnimation>
            :
            <>
              {!!contact.image.childImageSharp ?
                <Image
                  className="image-full"
                  style={{ height: "100%" }}
                  fluid={
                    !!contact.image.childImageSharp
                      ? contact.image.childImageSharp.fluid
                      : contact.image
                  }
                />
                :
                <img src={contact.image} style={{ height: "100%", maxHeight: "100vh" }} />
              }
            </>
          }
        </div>
        <div className="container">
          <div className="columns">

            <div className={`column is-half is-offset-half`}>
              <div className="content" style={{ padding: "12rem 2rem" }}>
                {preview === false ?
                  <ScrollAnimation animateIn={"fadeInRight"} animateOnce={true} duration={1.5}>
                    <h2 className="title is-uppercase has-text-weight-medium">
                      <span className="spray">
                        {contact.title}
                      </span>
                    </h2>
                    <h4 className="subtitle is-uppercase has-text-weight-medium" style={{ paddingTop: '2rem' }}>Contact Us</h4>
                    <ReactMarkdown
                      source={contact.info}
                      className="content"
                    />
                  </ScrollAnimation>
                  :
                  <>
                    <h2 className="title is-uppercase has-text-weight-medium">
                      <span className="spray">
                        {contact.title}
                      </span>
                    </h2>
                    <h4 className="subtitle is-uppercase has-text-weight-medium" style={{ paddingTop: '2rem' }}>Contact Us</h4>
                    <ReactMarkdown
                      source={contact.info}
                      className="content"
                    />
                  </>
                }
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  );

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        logo={frontmatter.logo}
        image={frontmatter.image}
        title={frontmatter.title}
        subtitle={frontmatter.subtitle}
        slides={frontmatter.slides}
        section1={frontmatter.section1}
        rooms={frontmatter.rooms}
        adventures={frontmatter.adventures}
        contact={frontmatter.contact}
        preview={false}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
        markdownRemark(frontmatter: {templateKey: {eq: "index-page" } }) {
        frontmatter {
        title
        subtitle
        logo {
        publicURL
          childImageSharp {
        fluid(maxWidth: 300, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
        image {
        childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
        section1 {
        description
          subTitle
      title
      videoID
          videoImage {
        absolutePath
            relativePath
      publicURL
            childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
        rooms {
        roomList {
        title
            description
            image {
        absolutePath
              relativePath
              childImageSharp {
        fluid(maxWidth: 2048, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
    roomId
  }
}
        slides {
        slideList {
        description
            title
            image1 {
        id
              childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
            image2 {
        id
              childImageSharp {
        fluid(maxWidth: 1200, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
            image3 {
        id
              childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
            image4 {
        id
              childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
}
        adventures {
        adventures
          description
      title
    }
        contact {
        info
          subtitle
      title
          image {
        id
            childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
        ...GatsbyImageSharpFluid
      }
      }
    }
  }
}
}
}
`;
