import React from "react";
import logo from "../img/onda-logo.png";
import Headroom from 'react-headroom';
import { Link } from "react-scroll";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: ""
    };
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
            navBarActiveClass: "is-active"
          })
          : this.setState({
            navBarActiveClass: ""
          });
      }
    );
  };

  render() {
    return (
      <Headroom style={{zIndex: 100}} disableInlineStyles onUnpin={() => this.state.active === true && this.toggleHamburger()}>
        <nav
          className="navbar is-transparent"
          role="navigation"
          aria-label="main-navigation"
          style={{ borderBottom: "1px solid #eee" }}
        >
          <div className="container">
            <div className="navbar-brand">
              <Link to="/" className="navbar-item" title="Logo">
                <img src={logo} alt="Kaldi" style={{ width: "88px" }} />
              </Link>
              {/* Hamburger menu */}
              <div
                className={`navbar-burger burger ${this.state.navBarActiveClass}`}
                data-target="navMenu"
                onClick={() => this.toggleHamburger()}
              >
                <span />
                <span />
                <span />
              </div>
            </div>
            <div
              id="navMenu"
              className={`navbar-menu ${this.state.navBarActiveClass}`}
            >
              <div className="navbar-end has-text-centered is-family-code is-uppercase is-size-6">
                <Link to="top" className="navbar-item" activeClass="is-active" spy={true} smooth={true} duration={800} onClick={() => this.toggleHamburger()}>
                  Home
              </Link>
                <Link to="queonda" className="navbar-item" activeClass="is-active" spy={true} smooth={true} duration={800} onClick={() => this.toggleHamburger()}>
                  Que Onda?
              </Link>
                <Link to="rooms" className="navbar-item" activeClass="is-active" spy={true} smooth={true} duration={800} onClick={() => this.toggleHamburger()}>
                  Rooms
              </Link>
                <Link to="amenities" className="navbar-item" activeClass="is-active" spy={true} smooth={true} duration={800} onClick={() => this.toggleHamburger()}>
                  Amenities
              </Link>
                <Link to="adventures" className="navbar-item" activeClass="is-active" spy={true} smooth={true} duration={800} onClick={() => this.toggleHamburger()}>
                  Adventures
              </Link>
                <Link to="contact" className="navbar-item" activeClass="is-active" spy={true} smooth={true} duration={800} onClick={() => this.toggleHamburger()}>
                  Contact
              </Link>
                <div className="navbar-item  is-size-7">
                  <a
                    href="https://hotels.cloudbeds.com/reservation/Pj8cCc"
                    className="button  is-size-6 is-primary is-outlined"
                    style={{ borderRadius: 0 }}
                  >
                    Book Now
                </a>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </Headroom>
    );
  }
};

export default Navbar;
