import React from 'react'
import ModalVideo from 'react-modal-video'
import Icon from '../img/icons/play-button.svg'

class VideoModal extends React.Component {

  constructor() {
    super()
    this.state = {
      isOpen: false
    }
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }

  render() {
    return (
      <>
        <ModalVideo api="true" channel='vimeo' isOpen={this.state.isOpen} videoId='361908949' autoplay="true" portrait="false" title="false" color="fbe446" byline="false" onClose={() => this.setState({ isOpen: false })} />
        <button className="" onClick={() => this.openModal()} style={{
          background: 'none',
          border: "none"
        }}>
          <figure className="image is-64x64" >
            <img src={Icon} alt="play-button" />
          </figure>
        </button>
      </>
    )
  }
}

export default VideoModal